import { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import DPSelectionDropDown from '../../common/DPSelectionDropDown';
import {
  colors,
  EXT_IMG,
  EXT_OTHER_IMG,
  EXT_PNG,
  pathServer, SUCCESSFUL_CODE,
  WIDTH_VIEW_MOBILE,
} from '../../../common/Constants'
import {
  SUBTASK_STATUS,
  TASK_STATUS_TODO,
  TASK_STATUS_DONE,
  TASK_STATUS_IN_PROGRESS
} from '../../Constants'
import {browserHistory} from 'react-router'
import { updateReorderTask } from '../../Api'
import {getRandomId} from "../../Utils";

const SubTask = (props) => {
  const isNewSubtask = props.locationState?.subTaskId === 'new';
  const isNewParentTask = props.taskId === 'new';

  const isNewTask = isNewParentTask || isNewSubtask;

  const normalizeData = (data) => {
    if (!data) return

    return data.map((item) => item.subTask ? item : { subTask: item });
  };
  const [subtasks, setSubtasks] = useState( props.data ? normalizeData(props.data) : []);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const calculateProgress = (subtasks) => {
    if (!subtasks || subtasks.length === 0) {
      return 0;
    }

    const totalProgress = subtasks.reduce((accumulator, el) => {
      const reduxStatus = el.subTask?.task?.status;
      const dbStatus = el.subTask?.status;
      const statusSelected =  reduxStatus || dbStatus || 'todo';

      const status = SUBTASK_STATUS.find(
          (statusItem) => statusItem.id === statusSelected
        );
      const progressValue = status ? status.progressValue : 0;
      return accumulator + progressValue;
    }, 0);

    const averageProgress = totalProgress / subtasks.length;

    return averageProgress % 1 === 0 ? averageProgress :  averageProgress.toFixed(2);
  };

  useEffect(() => {
    const progress = calculateProgress(subtasks);
    setProgressPercentage(progress);
  }, [subtasks, props.data]);

  useEffect(() => {
    if (props.data) setSubtasks(normalizeData(props.data));
  }, [props.data]);

  const goToSubTaskPage = (id = 'new') => {
    const taskInfo = {
      task: {
        id: props.taskId,
        title: props.parentTask.title,
        status: props.parentTask.status,
        priority: props.parentTask.priority,
        description: props.parentTask.description,
        typeTask: props.parentTask.typeTask,
        carId: props.parentTask.carId,
        assignedUserId: props.parentTask.assignedUserId || '',
        dueDate: props.parentTask.dueDate
      },
      comments: props.parentTask.comments || [],
      repairCost: props.parentTask.repairCost || []
    };

    props.saveTaskInfo(taskInfo);

    if (id !== 'new') {
      if (props?.data) {
        const existingSubTask = props.data.find(st =>
          (st.task?.id === id) || (st.subTask?.id === id)
        );
        const taskData = existingSubTask?.task || existingSubTask?.subTask;
        const comments = existingSubTask.comments || [];
        const costs = existingSubTask.repairCost?.listRepair || [];

        const formattedCosts = costs.map(cost => {
          const laborCost = cost.costs?.find(c => c.costType === 'labor')?.costValue;
          const partCost = cost.costs?.find(c => c.costType === 'part')?.costValue;

          const total = (parseFloat(laborCost || 0) + parseFloat(partCost || 0)).toFixed(2);

          return {
            id: cost.id,
            description: cost.description || 'Cost description',
            labor: formatCostValue(laborCost),
            part: formatCostValue(partCost),
            total: total,
            receipts: cost.receipts || [],
            temporalId: cost.id || getRandomId()
          };
        });


        if (taskData) {
          browserHistory.push({
            pathname: `/main/task/${props.taskId}/subtask/${id}`,
            state: {
              statusValue: existingSubTask.status,
              userAssigned: existingSubTask.assignedUserId,
              priorityValue: existingSubTask.priority,
              userComment: existingSubTask.comments,
              comments: comments,
              costs: formattedCosts,
              parentTask: props.parentTask,
              subTaskId: id,
              titleTaskParent: props.parentTask.title,
              originPath: `/main/task/${props.taskId}`,
              subtasksFromTask: subtasks,
              updateStatusTask: updateStatusTask
            }
          });
          return;
        }
      }
    }

    browserHistory.push({
      pathname: `/main/task/${props.taskId}/subtask/${id}`,
      state: {
        statusValue: props.statusValue,
        userAssigned: props.userAssigned,
        priorityValue: props.priorityValue,
        userComment: props.userComment,
        parentTask: props.parentTask,
        subTaskId: id,
        titleTaskParent: props.parentTask.title,
        originPath: `/main/task/${props.taskId}`,
        subtasksFromTask: subtasks,
        updateStatusTask: updateStatusTask
      }
    })
  };

  const updateStatusTask = (updatedSubtasks) => {
    const statusCount = updatedSubtasks.reduce((acc, task) => {
      const status = task.subTask.status;
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {todo: 0, progress: 0, done: 0});
    const taskStatusToUpdate = [{
      id: props.parentTask.id,
      status: props.parentTask.status
    }]

    const createTaskResult = (id, order, status) => ({
      id,
      order,
      status,
    });

    if (props.parentTask.status === TASK_STATUS_TODO) {
      if (statusCount.todo === 0 && statusCount.progress === 0 && statusCount.done > 0) {
        (taskStatusToUpdate.result = [createTaskResult(props.parentTask.id, 0, TASK_STATUS_DONE)]);
      } else if (statusCount.progress > 0 || statusCount.done > 0) {
        (taskStatusToUpdate.result = [createTaskResult(props.parentTask.id, 0, TASK_STATUS_IN_PROGRESS)]);
      }
    } else if (props.parentTask.status === TASK_STATUS_IN_PROGRESS) {
      if (statusCount.progress === 0 && statusCount.done === 0 && statusCount.todo > 0) {
        (taskStatusToUpdate.result = [createTaskResult(props.parentTask.id, 0, TASK_STATUS_TODO)]);
      } else if (statusCount.todo === 0 && statusCount.progress === 0 && statusCount.done > 0) {
        (taskStatusToUpdate.result = [createTaskResult(props.parentTask.id, 0, TASK_STATUS_DONE)]);
      }
    } else if (props.parentTask.status === TASK_STATUS_DONE) {
      if (statusCount.progress === 0 && statusCount.done === 0 && statusCount.todo > 0) {
        (taskStatusToUpdate.result = [createTaskResult(props.parentTask.id, 0, TASK_STATUS_TODO)]);
      } else if (statusCount.progress > 0 || statusCount.todo > 0) {
        (taskStatusToUpdate.result = [createTaskResult(props.parentTask.id, 0, TASK_STATUS_IN_PROGRESS)]);
      }
    }
    const taskToUpdate = taskStatusToUpdate?.result || null;
    if(taskToUpdate){
      let taskSelected = taskToUpdate.find(d => d.status);
      updateReorderTask({items: taskToUpdate, taskIdSelected: taskSelected.id, status: taskSelected.status}).then(response =>{
        const {responseCode, responseMessage} = response
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage)
        }else{
          setSubtasks(updatedSubtasks);
        }
      });
    }
  }

  const handleStatusChange = (subtask, subtaskId, newStatusId) => {
    const selectedStatusOption = SUBTASK_STATUS.find(
      (option) => option.id === newStatusId
    );

    if(isNewTask) {
      // Extract the task ID from the nested structure
      const taskId = subtask.subTask.task.id;

      // Update local state - keeping current nested structure
      const updatedSubtasks = subtasks.map((el) =>
        el.subTask.task.id === taskId
          ? {
              ...el,
              subTask: {
                ...el.subTask,
                task: {...el.subTask.task, status: newStatusId}
              }
            }
          : el
      );
      setSubtasks(updatedSubtasks);

      // For Redux, transform to unwrapped structure
      const unwrappedSubtask = {
        task: {...subtask.subTask.task, status: newStatusId},
        repairCost: subtask.subTask.repairCost,
        comments: subtask.subTask.comments
      };

      // Dispatch to Redux with unwrapped structure
      props.updateSubTask(unwrappedSubtask);
    } else {
      const subtaskId = subtask.subTask.id
      const items = [
        {
          id: subtaskId,
          order: 1,
          status: newStatusId
        }
      ];

      // Update local state for existing tasks
      const updatedSubtasks = subtasks.map((el) =>
        el.subTask.id === subtaskId
          ? {
              ...el,
              subTask: {...el.subTask, status: newStatusId}
            }
          : el
      );

      updateStatusTask(updatedSubtasks)

      updateReorderTask({items: items, taskIdSelected: subtaskId, status: newStatusId}).then(response => {
        const {responseCode, responseMessage} = response;
        if (responseCode !== SUCCESSFUL_CODE) {
          console.error(responseMessage);
        } else {
          setSubtasks(updatedSubtasks);
        }
      });
    }
  };

  const formatCostValue = (value) => {
    if (!value && value !== 0) return '0.00';
    const numberValue = parseFloat(value);
    return numberValue.toFixed(2);
  };

  const getSubTaskStatus = (subTask) => {
    const reduxStatus = subTask.subTask?.task?.status;
    const dbStatus = subTask.subTask?.status;
    return reduxStatus || dbStatus || 'todo';
  };

  const getDisplayValue = (el) => {
    return props.priorities.find((option) => option.id === (el.subTask.task?.priority || el.subTask.priority))?.displayValue;
  }

  const displayWithDecimals = (valueToDisplay) => {
    let formattedValue = '0.00'

    if (typeof valueToDisplay === 'string' && !isNaN(Number(valueToDisplay))) {
      formattedValue = parseFloat(valueToDisplay).toFixed(2)
    } else if (typeof valueToDisplay === 'number') {
      formattedValue = valueToDisplay.toFixed(2)
    }

    return formattedValue
  }

  const handleRemove = (evt, subTask) => {
    if(isNewTask) {
      const updatedSubtasks = subtasks.filter((el) =>
        el.subTask.task.id !== subTask.subTask.task.id
      );

      setSubtasks(updatedSubtasks);
      props.deleteSubTask(subTask)
    } else {
      props.openDeleteModal(evt, subTask.subTask.id)
    }
  }

  return (
    <>
      <div className='subtasks'>
        <div className='body-subtasks'>
          <div className='subtask-header'>
            <span className='title'>Subtasks</span>
            <progress
              id='task-completed'
              className='styled-progress'
              max='100'
              value={progressPercentage}
            ></progress>
            <span className='task-completed'>
              {progressPercentage}% Completed
            </span>
          </div>
          <div className='subtask-table'>
            <table>
              <thead>
                <tr>
                  <th className='title'>Title</th>
                  <th className='priority'>Priority</th>
                  <th className='status'>Status</th>
                  <th className='costs'>Costs</th>
                  <th className='x'></th>
                </tr>
              </thead>
              <tbody>
                {subtasks.length > 0 && subtasks.map((el) => (
                  <tr>
                    <td>
                      <span className={'subTask-title'} onClick={()=>goToSubTaskPage(el.subTask.task?.id || el.subTask.id)}>
                        {el.subTask.task?.title || el.subTask.title}
                      </span>
                    </td>
                    <td className={getDisplayValue(el).toLowerCase()}>{getDisplayValue(el)}</td>
                    <td>
                      <DPSelectionDropDown
                        options={SUBTASK_STATUS}
                        value={
                          SUBTASK_STATUS.find(
                            (option) => option.id === getSubTaskStatus(el)
                          )?.id || 'todo'
                        }
                        heightOfSelect={'47'}
                        onClick={(newStatusId) =>
                          handleStatusChange(el,el.subTask?.task?.id, newStatusId)
                        }
                        id='from-dropdown'
                        label=''
                        errorMessage={''}
                      />
                    </td>
                    <td>${
                      displayWithDecimals(
                        el.subTask?.repairCost?.totalCost || // Redux format
                        el.totalCost ||                     // API format
                        el.task?.repairCost?.totalCost ||   // Redux fallback
                        0                                   // Default if no cost found
                      )
                    }</td>
                    <td>
                      <div className='info-icon'>
                        <ReactSVG
                          src={`${pathServer.PATH_IMG}icon/ic_delete.svg`}
                          className='icon-action-content-delete'
                          onClick={(evt)=>handleRemove(evt, el)}
                          beforeInjection={(svg) =>
                            svg.classList.add('icon-action-content-delete')
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='add-subtask'>
              <span onClick={() => goToSubTaskPage('new')}>+ Add subtask</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubTask;
