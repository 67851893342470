import React, {Component} from 'react'
import {FILTER_TYPE_TASK, TASK_TYPE, TYPES_TASKS} from '../../Constants'
import {find, isEmpty} from 'lodash'
import Moment from 'moment'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import {ReactSVG} from 'react-svg'
import { pathServer, WIDTH_VIEW_MOBILE } from '../../../common/Constants'

import './TaskUpdated.scss'

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const priorities = [
  {id:"all", name:"All", color:"#005294", styleBorder:"5px #005294 solid"},
  {id:"low", name:"Low", color:"#2194F1", styleBorder:"5px #2194F1 solid"},
  {id:"medium", name:"Medium", color:"#F3A200", styleBorder:"5px #F3A200 solid"},
  {id:"high", name:"High", color:"#FF6565", styleBorder:"5px #FF6565 solid"}
];

export default class Task extends Component{

  constructor(){
    super();
  }

  render(){
    const {task,styleBorderLeft,editTask,typeSelected,deleteTask,changeActiveTask,changeInactiveTaskSubmit} = this.props;
    const {id, title, priority, dueDate, userFirstName, userImage, filterType} = task
    const priorityFound = find(priorities,(prior) => {return prior.id === priority});
    const dueDateMessage = dueDate ? `Due on ${Moment(dueDate).format('ll')}` : ``;
    let showMessage = "";
    const today = new Date(Moment(new Date()).format("MM/DD/YYYY")).getTime();
    if (dueDate && today > dueDate){
      showMessage = "OVERDUE"
    }
    return(
      <div className="container-list-task" onClick={(evt) => editTask(id,evt)}>
        <div className="list-task">
          <div className="task-bar" style={styleBorderLeft}/>
          {
            typeSelected === TASK_TYPE.NORMAL ?
              <div className="task-dots"/> : null
          }
          <div className="list-task-left">
            <ResponsiveEllipsis
              text={title}
              maxLine='1'
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
            {
              typeSelected === TASK_TYPE.NORMAL ?
                <h6>
                  <span style={{color: priorityFound.color}}>{`${priorityFound.name} Priority`}</span>
                  <span className="task-separator">|</span>
                  { isEmpty(showMessage) ?
                    <span>{dueDateMessage}</span> :
                    <span className="show-overdue">{showMessage}</span>
                  }
                </h6> :
                <h6>
                  <span className="priority-repair" style={{color: priorityFound.color}}>{`${priorityFound.name} Priority`}</span>
                  { isEmpty(showMessage) ?
                    <span>{dueDateMessage}</span> :
                    <span className="show-overdue">{showMessage}</span>
                  }
                </h6>
            }
          </div>
          <div className="list-task-right">
            <div className="list-task-assigned">
              <div className="list-task-number">
                <h6>{userFirstName ? userFirstName : ""} </h6>
              </div>
              <div className="list-task-img">
                <img src={userImage ? userImage : pathServer.PATH_IMG+"ic_account_circle.png"} />
              </div>
            </div>
            <div className="list-task-delete">
              {
                filterType === FILTER_TYPE_TASK[0].value ?
                  <i title='Inactive Task'  onClick={(evt) => changeInactiveTaskSubmit(id,evt)}>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/cancel-button.svg"}
                              beforeInjection={ svg => svg.classList.add("icon-inactive")}/>
                  </i>
                  :
                  <div className='options-archived-removed'>
                    <i title='Active Task' onClick={(evt) => changeActiveTask(id,evt)}>
                      <ReactSVG src={pathServer.PATH_IMG + "icon/add-button.svg"}
                                beforeInjection={ svg => svg.classList.add("icon-add")}/>
                    </i>
                    <i title='Delete Task' onClick={(evt) => deleteTask(id,evt)}>
                      <ReactSVG src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                beforeInjection={ svg => svg.classList.add("btn-delete")}/>
                    </i>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export const TaskUpdated = (props) => {
  const {task,styleBorderLeft,editTask,typeSelected,deleteTask,changeActiveTask,changeInactiveTaskSubmit,type,taskParent} = props;

    const {id, title, priority, dueDate, userFirstName, userImage, filterType, parentTaskId = null} = task
    const priorityFound = find(priorities,(prior) => {return prior.id === priority});
    const dueDateMessage = dueDate ? `Due on ${Moment(dueDate).format('ll')}` : ``;
    let showMessage = "";
    const today = new Date(Moment(new Date()).format("MM/DD/YYYY")).getTime();
    if (dueDate && today > dueDate){
      showMessage = "OVERDUE"
    }
    return(
      type === TYPES_TASKS.TASK
      ?
      <div className="container-list-task" onClick={(evt) => editTask(null,id,evt, parentTaskId)}>
        <div className="list-task">
          <div className="task-bar" style={styleBorderLeft}/>
          {
            typeSelected === TASK_TYPE.NORMAL ?
              <div className="task-dots"/> : null
          }
          <div className="list-task-left">
            <ResponsiveEllipsis
              text={title}
              maxLine='3'
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
            {
              typeSelected === TASK_TYPE.NORMAL ?
                <h6>
                  <span style={{color: priorityFound.color}}>{`${priorityFound.name} Priority`}</span>
                  <span className="task-separator">|</span>
                  { isEmpty(showMessage) ?
                    <span>{dueDateMessage}</span> :
                    <span className="show-overdue">{showMessage}</span>
                  }
                </h6> :
                <div className="task-bottom">
                  <span className="priority-repair-updated" style={{color: priorityFound.color}}>{`${priorityFound.name} Priority`}</span>
                  { isEmpty(showMessage) ?
                    <span className="task-dueDate">{dueDateMessage}</span> :
                    <span className="show-overdue">{showMessage}</span>
                  }
                </div>
            }
          </div>
          <div className="list-task-right">
            <div className="list-task-assigned">
              <div className="list-task-number">
                <span className="username">{userFirstName ? userFirstName : ""} </span>
              </div>
              <div className="list-task-img">
                <img src={userImage ? userImage : pathServer.PATH_IMG+"ic_account_circle.png"} />
              </div>
            </div>
            <div className="list-task-delete">
              {
                filterType === FILTER_TYPE_TASK[0].value ?
                  <i title='Inactive Task'  onClick={(evt) => changeInactiveTaskSubmit(id,evt)}>
                    <ReactSVG src={pathServer.PATH_IMG + "icon/cancel-button.svg"}
                              beforeInjection={ svg => svg.classList.add("icon-inactive")}/>
                  </i>
                  :
                  <div className='options-archived-removed'>
                    <i title='Active Task' onClick={(evt) => changeActiveTask(id,evt)}>
                      <ReactSVG src={pathServer.PATH_IMG + "icon/add-button.svg"}
                                beforeInjection={ svg => svg.classList.add("icon-add")}/>
                    </i>
                    <i title='Delete Task' onClick={(evt) => deleteTask(id,evt, type)}>
                      <ReactSVG src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                beforeInjection={ svg => svg.classList.add("btn-delete")}/>
                    </i>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
        : type === TYPES_TASKS.SUBTASK
      ?
          <div className="container-list-task" onClick={(evt) => editTask(taskParent,id,evt, parentTaskId)}>
            <div className="list-task">
              <div className="task-bar" style={styleBorderLeft}/>
              {
                typeSelected === TASK_TYPE.NORMAL ?
                  <div className="task-dots"/> : null
              }
              <div className="list-task-left">
                <ResponsiveEllipsis
                  text={title}
                  maxLine='3'
                  ellipsis='...'
                  trimRight
                  basedOn='letters'
                />
                {
                  typeSelected === TASK_TYPE.NORMAL ?
                    <h6>
                      <span style={{color: priorityFound.color}}>{`${priorityFound.name} Priority`}</span>
                      <span className="task-separator">|</span>
                      { isEmpty(showMessage) ?
                        <span>{dueDateMessage}</span> :
                        <span className="show-overdue">{showMessage}</span>
                      }
                    </h6> :
                    <div className="task-bottom">
                      <span className="priority-repair-updated" style={{color: priorityFound.color}}>{`${priorityFound.name} Priority`}</span>
                      { isEmpty(showMessage) ?
                        <span className="task-dueDate">{dueDateMessage}</span> :
                        <span className="show-overdue">{showMessage}</span>
                      }
                    </div>
                }
              </div>
              <div className="list-task-right">
                <div className="list-task-assigned">
                  <div className="list-task-number">
                    <span className="username">{userFirstName ? userFirstName : ""} </span>
                  </div>
                  <div className="list-task-img">
                    <img src={userImage ? userImage : pathServer.PATH_IMG+"ic_account_circle.png"} />
                  </div>
                </div>
                <div className="list-task-delete">
                  {
                    filterType === FILTER_TYPE_TASK[0].value ?
                      <i title='Delete Task' onClick={(evt) => deleteTask(id,evt, type)}>
                        <ReactSVG src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                  beforeInjection={ svg => svg.classList.add("btn-delete")}/>
                      </i>
                      // <i title='Inactive Task'  onClick={(evt) => changeInactiveTaskSubmit(id,evt)}>
                      //   <ReactSVG src={pathServer.PATH_IMG + "icon/cancel-button.svg"}
                      //             beforeInjection={ svg => svg.classList.add("icon-inactive")}/>
                      // </i>
                      :
                      <div className='options-archived-removed'>
                        <i title='Active Task' onClick={(evt) => changeActiveTask(id,evt)}>
                          <ReactSVG src={pathServer.PATH_IMG + "icon/add-button.svg"}
                                    beforeInjection={ svg => svg.classList.add("icon-add")}/>
                        </i>
                        <i title='Delete Task' onClick={(evt) => deleteTask(id,evt, type)}>
                          <ReactSVG src={pathServer.PATH_IMG + "icon/trash-icon.svg"}
                                    beforeInjection={ svg => svg.classList.add("btn-delete")}/>
                        </i>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        : ""
    )

}
export const TaskHeader = (props) => {
  const {openIs, progress, task, changeToInactiveTask, editTask} = props
  const titleHeader = window.screen.width < WIDTH_VIEW_MOBILE ? `${progress}%` : `${progress}% Completed`
  return (
    <div className="taskHeader" style={{borderRadius: '8px'}}>
      <div className="header-left">
        <i title='Active Task' onClick={(evt) => openIs(task.id,evt)}>
          <ReactSVG src={task.showSubtasks ? pathServer.PATH_IMG + 'icon/ic_expand_more.svg' :  pathServer.PATH_IMG + 'icon/ic_expand_less.svg'}
                    beforeInjection={ svg => svg.classList.add("icon-svg")}/>
        </i>
        <span onClick={(evt) => editTask(task,task.id, evt)}>{task.title}</span>
      </div>
      <div className="header-right">
        <progress
          className="styled-progress"
          id='task-completed'
          max='100'
          value={progress}
        ></progress>
        <span className='task-completed'>
             {titleHeader}
      </span>
        <i title='Inactive Task'  onClick={(evt) => changeToInactiveTask(task.id, task.subtasks, evt)}>
          <ReactSVG src={pathServer.PATH_IMG + "icon/cancel-button.svg"}
                    style={{fill: 'red', paddingRight: '10px'}}
                    beforeInjection={ svg => svg.classList.add("icon-svg")}/>
        </i>
      </div>
    </div>
  )
}