import React, {Component} from 'react'
import {browserHistory} from 'react-router'
import ContainerHeader from '../util/ContainerHeader.jsx'
import {connect} from 'react-redux'
import {
  FILTER_TYPE_TASK,
  SIDEBAR_WIDTH_EXPANDED,
  SIDEBAR_WIDTH_UNEXPANDED,
  TASK_NORMAL_PLACE_HOLDER,
  TASK_REPAIR_PLACE_HOLDER,
  TASK_TYPE
} from '../../Constants'
import TaskDetail from './TaskDetail.jsx'
import {setPlaceHolder} from '../util/Utils'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import {
  clearSubTasks,
  deleteSubTask,
  loadLocations,
  loadMakeAndModel,
  saveTaskInfo,
  searchInventory,
  setSubTasks,
  updateSubTask
} from '../../actions'
import isEmpty from 'lodash/isEmpty.js'
import ModalConfirmDelete from '../util/ModalConfirmDelete'
import {deleteTaskApi, loadBoards, updateTaskActiveApi} from '../../Api'
import ModalConfirmDeactivate from '../util/ModalConfirmDeactivate'
import {forEach, find} from 'lodash'
import {SUCCESSFUL_CODE} from '../../../common/Constants'
import SubTaskDetail from "./SubTaskDetail";

export const priorities = [{id:"low", displayValue:"Low", color:"#2194F1"},{id:"medium", displayValue:"Medium", color:"#F3A200"},{id:"high", displayValue:"High", color:"#FF6565"}];

class TaskEdit extends Component {
  constructor(props) {
    super(props);
    this.taskInfo = props.taskInfo ;
    this.hasParentTask = props.params.id && props.params.carId;
    const isNewSubTaskOfNewTask = props.location.pathname.includes('task/new/subtask/new');
    this.subTaskDetail = React.createRef();
    this.back   = this.back.bind(this);
    this.save   = this.save.bind(this);
    this.handleModalChange  = this.handleModalChange.bind(this);
    this.handleUpdateData   = this.handleUpdateData.bind(this);
    this.validationModal    = this.validationModal.bind(this);
    this.updateTitleTask    = this.updateTitleTask.bind(this);
    this.updateEditTask     = this.updateEditTask.bind(this);
    this.openModalDelete    = this.openModalDelete.bind(this);
    this.openModalActive    = this.openModalActive.bind(this);
    this.changeActiveTask   = this.changeActiveTask.bind(this);
    this.deleteTask         = this.deleteTask.bind(this);
    this.updateSubTaskInRedux = this.updateSubTaskInRedux.bind(this);
    this.state = {
      showModal:false,
      message:"Are you sure you want to save these changes?",
      hasChange:false,
      fromBack:false,
      titleTask:"",
      editTask: true,
      showModalDeleteTask : false,
      showModalActiveTask : false,
      repairId:null,
      isLoading: true,
      isSubtask: true,
      taskData: this.hasParentTask ? {} : {
        ...this.taskInfo?.task || {},
        repairCost: this.taskInfo?.repairCost || [],
        comments: this.taskInfo?.comments || []
      },
      subTasks: isNewSubTaskOfNewTask ? [] : null,
      updatedSubtasks: [],
      reload: false
    }

  }

  async componentDidMount(){
    const {searchInventory,loadMakeAndModel,loadLocations, taskFilter} = this.props;
    const {id, carId, repairId} = this.props.params;

    this.setState({isLoading: true})
    let state = {isLoading: false}

      if(taskFilter.statusesByType === undefined){
        const {responseCode, data} = await loadBoards()
        if (responseCode === SUCCESSFUL_CODE) {
          const statusesByType = data.statusesByType
          forEach(statusesByType, st => {
            st.statuses = JSON.parse(st.statuses)
          })
          state.statusesByType = statusesByType
        } else {
          this.setState(state)
        }
      }else{
        state.statusesByType = taskFilter.statusesByType
      }
      if(id === 'new' && carId && repairId){
        state.hasChange = true
      }
      await searchInventory({active: true})
      await loadMakeAndModel();
      await loadLocations();
      this.setState(state)
  }

  componentDidUpdate(prevProps){
    const {taskFilter} = this.props
    setPlaceHolder("menu-search", taskFilter && (taskFilter?.type  === 'repair') ? TASK_REPAIR_PLACE_HOLDER : TASK_NORMAL_PLACE_HOLDER);

    if (prevProps.taskInfo !== this.props.taskInfo) {

      const hasParentTask = this.props.params.id && this.props.params.carId;
      if (!hasParentTask && this.props.taskInfo?.task) {
        const { task, comments, repairCost } = this.props.taskInfo;

        this.setState({
          taskData: {
            ...task,
            repairCost: repairCost || [],
            comments: comments || []
          },
          carSelected: task.carId ? this.getCarDisplayName(task.carId) : "Select car"
        });

      }
    }
  }
  getCarDisplayName(carId) {
    const { cars, makes, models } = this.props;
    const car = cars.find(c => c.id === carId);
    if (car) {
      const make = makes.find(m => m.id === car.makeId);
      const model = models.find(m => m.id === car.modelId);
      return `${make?.name} ${model?.name}`;
    }
    return "Select car";
  }
  componentWillUnmount() {
    this.props.clearSubTasks();
  }

  updateTitleTask(title){
    this.setState({titleTask:title})
  }

  updateEditTask(status){
    const isDisabled = status === FILTER_TYPE_TASK[1].value || status === FILTER_TYPE_TASK[2].value ;
    this.setState({editTask: !isDisabled})
  }

  openModalDelete(evt, idToDelete = null){
    evt.stopPropagation();
    this.setState({showModalDeleteTask: true, idToDelete});
  }

  openModalActive(evt){
    evt.stopPropagation();
    this.setState({showModalActiveTask: true});
  }

 changeActiveTask() {
    let {id} = this.props.params;
    updateTaskActiveApi(id).then(({responseCode}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.setState({showModalDeleteTask:false})
        browserHistory.push({pathname: "/main/task"});
      }
    })
  }
 deleteTask() {
   const {idToDelete, isSubtask} = this.state
   const {id, subtaskId} = this.props.params
   const currentId = subtaskId ? subtaskId : (idToDelete ? idToDelete : id)

   const isSubTaskPage = this.props.location.pathname.includes('subtask')
   const currentPathname = browserHistory.getCurrentLocation().pathname
   const nextPathName = currentPathname.substring(0, currentPathname.indexOf('subtask'))

    deleteTaskApi(currentId).then(({responseCode}) => {
      if (responseCode !== SUCCESSFUL_CODE) {
        return
      }

      if (isSubtask && !isSubTaskPage) {
        this.setState({showModalDeleteTask: false}, () => {
          this.taskDetail.reloadTask()
        })
        return
      }
      if (isSubtask && isSubTaskPage) {
        this.setState({showModalDeleteTask: false})
        browserHistory.push({pathname: nextPathName})
        return
      }

      this.setState({showModalDeleteTask:false})
      browserHistory.push({pathname: "/main/task"})
    })
  }
  back(){
    const {hasChange} = this.state;
    const {carId} = this.props.params
    const {cars} = this.props
    const {titleTask} = this.state
    const originPath = this.props.location.state?.originPath

    if(originPath){
      browserHistory.push({pathname: originPath})
    }else if (hasChange){
      if (!this.hasParentTask && this.props.location.pathname.includes('task/new/subtask/new')) {
        browserHistory.push({pathname: "/main/task/new"});
      }
      else if(titleTask === ""){
        browserHistory.push({pathname: "/main/task"});
      }else{
        this.validationModal();
        this.setState({fromBack:true})
      }
    } else if (!isEmpty(carId)) {
      const carFound = find(cars, c => c.id === carId)
      browserHistory.push({pathname: `/main/inventory/${carFound?.vin}`})
    } else {
      browserHistory.push({pathname: "/main/task"});
    }
  }

  save(){
    const {params, location} = this.props
    let {carId, repairId} = params
    if(!repairId){
      repairId = this.state.repairId
    }
    const isSubTask = location.pathname.includes('subtask')
    const isNewSubTaskOfNewTask = location.pathname.includes('task/new/subtask/new')
    this.setState({showModal:false},() => {
      if (isNewSubTaskOfNewTask) {
        const { subTasks } = this.state;
        this.saveSubTasksInRedux(carId, repairId, subTasks);
        return
      }

      if (isSubTask) {
        const isExistingSubTaskOfNewTask = location.pathname.includes('task/new/subtask')
        if (isExistingSubTaskOfNewTask ) {
          this.updateSubTaskInRedux()
        } else {
          this.subTaskDetail.current.validationData(carId, repairId)
        }
        return
      }
      // this.taskDetail.validationData(carId, repairId);
      const subTasks =  this.props.subTasks
      this.taskDetail.saveTaskSubTasks(subTasks);
    });
  }

  updateRepairId = (repairId) => {
    this.setState({repairId})
  }

  handleModalChange(){
    const {fromBack} = this.state;
    this.setState({showModal:false});
    if(fromBack){
      this.setState({hasChange:false});
      browserHistory.push({pathname: "/main/task"});
    }
  }

  handleUpdateData(id){
    this.setState({[id]:true})
  }

  validationModal(){
    const isSubTask = this.props.location.pathname.includes('subtask')
    let error = isSubTask ? this.subTaskDetail.current.getError() : this.taskDetail.getError();

    if (isEmpty(error)) {
      this.setState({showModal: true});
    } else {
      if (isSubTask) {
        this.subTaskDetail.current.settingError();
      } else {
        this.taskDetail.setError();
      }
    }
  }

  openSubtask() {
    //logic for open the subtask from the taskDetail
    const {isSubtask} = this.state
    this.setState({isSubtask: !isSubtask})
  }

  saveSubTasksInRedux(carId, repairId, subTasks) {
    const subTaskData = this.subTaskDetail.current.getTaskData();

    this.props.setSubTasks(subTaskData); // Dispatch to Redux

    browserHistory.replace({
      pathname: '/main/task/new',
      state: {fromSubTasks: true} // Flag to know we're coming from subtasks
    });
  }

  updateSubTaskInRedux() {
    const subTaskData = this.subTaskDetail.current.getTaskData();

    this.props.updateSubTask(subTaskData);

    browserHistory.replace({
      pathname: '/main/task/new',
      state: {fromSubTasks: true} // Flag to know we're coming from subtasks
    });
  }

  handleSubTaskValidationData = (requestForm) => {
    this.setState((prevState) => ({
      subTasks: [...prevState.subTasks, requestForm.task],
    }));
  };


  render() {
    let {id, carId, repairId} = this.props.params;
    let {showSideBar, cars, makes, models, locations, activeUsers, email, taskFilter} = this.props;
    const isSubTaskBool = this.props.location.pathname.includes('subtask')
    const { repairSelected } = this.props.location.state || {}
    const {showModal,showModalDeleteTask, showModalActiveTask, hasChange, message, titleTask, editTask, isLoading, statusesByType, isSubtask, taskData, updatedSubtasks} = this.state;
    let left;
    if(showSideBar){
      left = SIDEBAR_WIDTH_EXPANDED;
    } else{
      left = SIDEBAR_WIDTH_UNEXPANDED;
    }

    const type = !isEmpty(carId) ? TASK_TYPE.REPAIR : taskFilter?.type;
    const isNewTask  = (id === 'new') ;
    const isNewSubTaskOfNewTask = this.props.location.pathname.includes('task/new/subtask/new');
    const isNewSubtask = (this.props.location.state?.subTaskId === 'new')

    return (
      <div className="container-task-edit">
        <ContainerHeader  style={{marginLeft: "0" , width : "55px"}}
                          ref={el => this.containerHeader = el}
                          title="Task"
                          isEdit={true}
                          data={isNewSubtask ? `${this.props.location.state.titleTaskParent} / Subtask` : this.props.location.state?.titleTaskParent ? `${this.props.location.state?.titleTaskParent} / ${titleTask}` : titleTask }
                          actionBack={this.back}
                          save={this.validationModal}
                          hasChange={hasChange}
                          left={left}
                          showOnMobile={true}
                          isEditTask={editTask}
                          isNewTask={isNewTask}
                          deleteTask={this.openModalDelete}
                          activeTask={this.openModalActive}
                          archivedTask={this.changeActiveTask}
                          pageSelected={isSubTaskBool ? 'subTask' : "task"}/>
        {
          !isLoading && !isSubTaskBool &&
          <TaskDetail id={id}
                      carId={carId}
                      ref={el => this.taskDetail = el}
                      handleUpdateData={this.handleUpdateData}
                      back={this.back}
                      isEdit={true}
                      hasChange={hasChange}
                      save={this.save}
                      users={activeUsers}
                      makes={makes}
                      models={models}
                      cars={cars}
                      updateTitleTask={this.updateTitleTask}
                      updateEditTask={this.updateEditTask}
                      locations={locations}
                      type={type}
                      email={email}
                      repairCost={ repairSelected ? {...repairSelected, id: repairId} : repairSelected}
                      updateRepairId={this.updateRepairId}
                      statusesByType={statusesByType}
                      openSubtask={this.openSubtask}
                      openDeleteModal={this.openModalDelete}
                      taskData={this.hasParentTask ? null : taskData}
                      saveTaskInfo={this.props.saveTaskInfo}
                      setSubTasks={this.setSubTasks}
                      updateSubTask={this.props.updateSubTask}
                      deleteSubTask={this.props.deleteSubTask}
                      subTasks={this.props.subTasks}
          />
        }

        {
            !isLoading && isSubTaskBool &&
            <SubTaskDetail
                ref={this.subTaskDetail}
                parentTaskId={id}
                users={activeUsers}
                locationState={this.props.location.state || {}}
                handleUpdateData={this.handleUpdateData}
                updateRepairId={this.updateRepairId}
                updateTitleTask={this.updateTitleTask}
                email={email}
                statusesByType={statusesByType}
                saveTaskInfo={this.props.saveTaskInfo}
                subTasks={this.props.subTasks}
                updateSubTasks={isNewSubTaskOfNewTask ? this.updateSubTasks : undefined}
                handleSubTaskValidationData={this.handleSubTaskValidationData}
            />
        }
        {
          showModal &&
            <GenericModalConfirm show={showModal}
                                 message={message}
                                 save={this.save}
                                 onHide={this.handleModalChange}
                                 showButtonCancel={true}
                                 buttonLabelSave="Save"
                                 classButtonSave="btn-save"
            />
        }

        {
          showModalDeleteTask &&
          <ModalConfirmDelete show={showModalDeleteTask}
                              showButtonDelete={true}
                              onSubmit={this.deleteTask}
                              onHide={() => {
                                this.setState({showModalDeleteTask: false})
                              }}
                              classButtonDelete="btn-delete-task"
                              buttonLabelDelete="Yes, Delete"
                              message={isSubtask ? "Do you want to delete this subtask permanently?" : "Do you want to delete this task permanently?"}
                              body={<div className="container-message-delete-task-in">
                                <span className="title">{isSubtask ? "Do you want to delete this subtask permanently?" : "Do you want to delete this task permanently?"}</span>
                                <span className="sub-title">This action is irreversible</span>
                              </div>}
                              styleModal="modal-delete"
                              styleRight="panel-right-delete-user"/>
        }

        {
          showModalActiveTask &&
          <ModalConfirmDeactivate
                                show={showModalActiveTask}
                                onSubmit={this.changeActiveTask}
                                onHide={() => {this.setState({showModalActiveTask:false})}}
                                showButton={true}
                                isBodyCustom={true}
                                bodyMessage={<div className="container-message-activate-task-in">
                                  <span className="title">Do you want to activate this task?</span>
                                  <span className="sub-title">Once activated, it'll appear on principal task page</span>
                                </div>}
                                classButtonDelete="btn-delete"
                                buttonLabelDelete={"Yes, activate."}
                                viewMobile={false}
                                iconQuestion={"icon/ic_question_warning_activate.svg" }
                                showAnotherMessage = {true}/>
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    cars:state.cars,
    makes:state.makes,
    models:state.models,
    locations:state.locations,
    email:state.email,
    taskFilter:state.taskFilter,
    activeUsers:state.activeUsers,
    taskInfo: state.taskReducer.taskInfo,
    subTasks: state.taskReducer.subTaskInfo.subTasks,
  }
};

export default connect(mapStateToProps,{searchInventory,loadMakeAndModel,loadLocations, saveTaskInfo, setSubTasks,
  clearSubTasks, updateSubTask, deleteSubTask})(TaskEdit)