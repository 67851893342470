import React, {useEffect, useImperativeHandle, useState} from 'react'
import {
  ASSIGNED_USER_ID,
  COST_REPAIR_LABOR,
  COST_REPAIR_PART,
  FILTER_TYPE_TASK,
  PRIORITY,
  STATUS,
  TASK_STATUS_COMPLETE,
  TASK_STATUS_DONE,
  TASK_STATUS_TODO,
  TASK_TYPE,
  USER_NAME_NOT_ASSIGNED
} from '../../Constants'
import {colors, pathServer, SUCCESSFUL_CODE} from '../../../common/Constants'
import Loader from '../../../common/UI/Loader'
import {DPSectionHeader} from '../../common/DPSectionHeader'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import {ReactSVG} from 'react-svg'
import DPKeyboardDatePicker from '../../common/DPKeyboardDatePicker'
import DPCost from '../../common/cost/DPCost'
import Moment from 'moment/moment'
import {getRandomId, getRepairsWithCosts, usdNumberToFormat} from '../../Utils'
import {deleteItemArray, formatToNumber, getErrorsInCosts, getPositionById} from "../util/Utils";
import {Popover} from "../util/ComponentUtils";
import {
  addReceiptRepair,
  deleteReceiptRepair,
  deleteTaskCostApi,
  loadTasksById,
  processTaskApi,
  saveTaskCost
} from "../../Api";
import {cloneDeep} from "lodash";
import isEmpty from "lodash/isEmpty";
import {browserHistory} from "react-router";
import {priorities} from "./TaskEdit";

require('./SubtaskDetail.scss')

function getNameUserSelected(userName){
  let nameUSerSelected = '';
  if(userName){
    nameUSerSelected = userName && userName.length > 15 ? `${userName.substring(0,15)} .` : userName
  }else{
    nameUSerSelected = USER_NAME_NOT_ASSIGNED;
  }
  return nameUSerSelected;
}

function getCostInitial(){
  return {description: 'New brakes', labor:'' , part: '', total: '',receipts: []}
}

function getRepairInitial() {
  return {priority: "low", description: "", typeRepair: TASK_TYPE.REPAIR, code: "", cost: 0, assignedTo: '', vendor: '', status: TASK_STATUS_TODO,
    carServices: null, carId: ''}
}

const SubTaskDetail = React.forwardRef((props, ref) => {
  const { subTasks: propSubTasks, updateSubTasks } = props
  const [isLoading, setIsLoading] = useState(false)
  const [isClosed, setIsClosed] = useState(false)
  const [task, setTask] = useState({
    title: '',
    typeTask: 'repair',
    status: TASK_STATUS_TODO,
    priority: 'low',
    description: '',
    // dueDate: Moment(Date.now()).format('MM/DD/YYYY'),
    dueDate: null,
    carId: props.locationState?.parentTask?.carId || '',
    parentTaskId: props.locationState?.parentTask?.id,
    assignedUserId: props.locationState?.parentTask?.assignedUserId
  })
  const [statusValue, setStatusValue] = useState(props.locationState.statusValue || null)
  const [selectOpened, setSelectedOpened] = useState(null)
  const [priorityValue, setPriorityValue] = useState(props.locationState.priorityValue || null)
  const [priorityColorValue, setPriorityColorValue] = useState('#2194F1')
  const [comments, setComments] = useState([])
  const [userComment, setUserComment] = useState(props.locationState.userComment || {})
  const [newComment, setNewComment] = useState('')
  const [costs, setCosts] = useState([])
  const [showPopover, setShowPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState({})
  const [options, setOptions] = useState([])
  const [optionSelected, setOptionSelected] = useState('')
  const [userAssigned, setUserAssigned] = useState(props.locationState.userAssigned || {})
  const [error, setError] = useState({})
  const [cost, setCost] = useState(getCostInitial())
  const [repair, setRepair] = useState(getRepairInitial())
  const [receiptsImages, setReceiptsImages] = useState([])
  const [usersSelected, setUsersSelected] = useState([])
  const [selectedStatuses, setSelectedStatuses] = useState([])

  useImperativeHandle(ref, () => ({
    getError,
    settingError,
    validationData,
    getTaskData
  }))

  useEffect(() => {
    let updatedUserComment = {};
    let updatedUsersSelected = [];

    const usersActive = props.users.filter(user => {return user.active === true});
    usersActive.forEach(user => {
      updatedUsersSelected.push({id: user.id, displayValue: user.firstName + ' ' + user.lastName, image: user.image, initials: user.firstName.substring(0,1) + user.lastName.substring(0,1)});
      if (user.email === props.email){
        updatedUserComment.id = user.id;
        updatedUserComment.image = user.image;
        updatedUserComment.initials = user.firstName.substring(0,1) + user.lastName.substring(0,1);
      }
    });

    if (props.locationState.subTaskId !== "new" && !(task.parentTaskId === "new" || task.parentTaskId == undefined)) {
      loadTasksById(props.locationState.subTaskId).then(({responseCode, data, responseMessage}) => {
        if (responseCode === SUCCESSFUL_CODE) {
          const {task, comments, typeFilter, template, costs, subTasks} = data.tasks;
          let templateToSave = {}
          let costsToSave = []

          if(task.type === "repair"){
            costsToSave = getRepairsWithCosts(costs)
          }

          task.typeTask = "repair"

          const selectedStatusesByType = props?.statusesByType.find(st => { return st.type === task.type  })
          const updatedSelectedStatuses = selectedStatusesByType.statuses

          let updatedStatusValue = ''

          updatedSelectedStatuses.forEach(status => {
            if (status.id === task.status)
              updatedStatusValue = status.displayValue;
          })

          let updatedUserAssigned = {}

          if(task.assignedUserId !== ""){
            updatedUsersSelected.forEach(user => {
              if (user.id === task.assignedUserId){
                updatedUserAssigned.id = user.id;
                updatedUserAssigned.name = user.displayValue;
                updatedUserAssigned.image = user.image;
                updatedUserAssigned.initials = user.initials;
              }
            });

            if(!updatedUserAssigned.id){
              const userFound = updatedUsersSelected.find(user => {return user.id === task.assignedUserId});
              if(userFound){
                updatedUserAssigned.id = userFound.id;
                updatedUserAssigned.name = userFound.firstName + ' ' + userFound.lastName;
                updatedUserAssigned.image = userFound.image;
                updatedUserAssigned.initials = userFound.firstName.substring(0,1) + userFound.lastName.substring(0,1);
              }
            }
          }

          let updatedPriorityValue
          let updatedPriorityColorValue

          priorities.forEach(priority => {
            if (priority.id === task.priority){
              updatedPriorityValue = priority.displayValue;
              updatedPriorityColorValue = priority.color
            }

          });

          if(typeFilter === FILTER_TYPE_TASK[0].value || typeFilter === FILTER_TYPE_TASK[1].value || typeFilter === FILTER_TYPE_TASK[2].value){
            task.typeFilter = typeFilter;
          }

          setTask(task)
          setCosts(costsToSave)
          setStatusValue(updatedStatusValue)
          setUserAssigned(updatedUserAssigned)
          setPriorityValue(updatedPriorityValue)
          setPriorityColorValue(updatedPriorityColorValue)
          setComments(comments)
          props.updateTitleTask(task.title)
          setSelectedStatuses(updatedSelectedStatuses)
        }
      })
    }else{
      const statusUpdate = props.statusesByType.find(item => item.type === 'repair')
      setSelectedStatuses(statusUpdate.statuses)
    }

    setUsersSelected(updatedUsersSelected)
    setUserComment(updatedUserComment)
  }, []);


  useEffect(()=>{
    const subTaskFound = props.subTasks.filter((subTask) =>
      subTask.task.id === props.locationState.subTaskId
    )
    if(subTaskFound[0]?.task) {
      const foundTask = subTaskFound[0].task;
      setTask(foundTask)

      // Update select values based on task data
      if (foundTask.status) {
        const foundStatus = selectedStatuses.find(s => s.id === foundTask.status);
        if (foundStatus) {
          setStatusValue(foundStatus.displayValue);
        }
      }

      if (foundTask.priority) {
        const foundPriority = priorities.find(p => p.id === foundTask.priority);
        if (foundPriority) {
          setPriorityValue(foundPriority.displayValue);
          setPriorityColorValue(foundPriority.color);
        }
      }

      if (foundTask.assignedUserId) {
        const foundUser = usersSelected.find(u => u.id === foundTask.assignedUserId);
        if (foundUser) {
          setUserAssigned({
            id: foundUser.id,
            name: foundUser.displayValue,
            image: foundUser.image,
            initials: foundUser.initials
          });
        }
      }

      if (props.locationState.costs) {
        setCosts(props.locationState.costs);
      }

      if (props.locationState.comments) {
        setComments(props.locationState.comments);
      }
    }
  },[propSubTasks, selectedStatuses, priorities, usersSelected])

  const onChangeTextTaskInfo = (evt) => {
    let updatedTask = {...task}
    let id = evt.target.id;
    updatedTask[id] = evt.target.value
    setTask(updatedTask)
    setError(null)
    props.handleUpdateData("hasChange");
  }

  const handleChangeDate = (id, newDate) => {
    let date = Moment(newDate).format('MM/DD/YYYY')
    let newTask = {...task}
    newTask.dueDate = date
    setTask(newTask)
    props.handleUpdateData("hasChange");
  }

  const onChangeNewComment = (evt) => {
    setNewComment(evt.target.value)
    props.handleUpdateData("hasChange");
  }

  const addNewCost = () => {
    const defaultCost = {
      description: '',
      part: '',
      labor: '',
      total: '',
      receipts: [],
      temporalId: getRandomId(),
    };
    setCosts([...costs, defaultCost])
  }

  const closeSection = () => {
    setIsClosed(!isClosed)
  }

  const handlePopover = (id, event) => {
    event.preventDefault();

    let options = [];
    let category = "";
    let optionSelected = "";
    let anchorEl = getPositionById(id, true)

    switch (id) {
      case "assignedUserId":
        category = "info";
        options = usersSelected;
        optionSelected = userAssigned.id;
        break;
      case "status":
        category = "info";
        options = selectedStatuses
        optionSelected = statusValue;
        break;
      case "priority":
        category = "info";
        options = priorities;
        optionSelected = priorityValue;
        break;
    }

    setAnchorEl(anchorEl)
    setShowPopover(true)
    setSelectedOpened(id)
    setOptions(options)
    setOptionSelected(optionSelected)
  }

  const handleRequestClose = () => {
    setShowPopover(false)
    setSelectedOpened(null)
  }

  const onClickPopover = (id) => {
    let updatedTask = {...task}

    if(selectOpened === "assignedUserId"){
      usersSelected.forEach(user => {
        if (user.id === id){
          let updatedUserAssigned = {...user}
          updatedUserAssigned.name = user.displayValue;
          updatedUserAssigned.image = user.image;
          setUserAssigned(updatedUserAssigned)
        }
      })
    }

    if(selectOpened === "status"){
      selectedStatuses.forEach(status => {
        if (status.id === id){
          setStatusValue(status.displayValue)
        }
      })
    }

    if(selectOpened === "priority"){
      priorities.forEach(priority => {
        if (priority.id === id) {
          setPriorityValue(priority.displayValue)
          setPriorityColorValue(priority.color)
        }
      })
    }

    updatedTask[selectOpened] = id
    setTask(updatedTask)
    setShowPopover(false)
    setSelectedOpened('')
    props.handleUpdateData("hasChange");
  }

  const renderOptions = () => {
    let values = [];
    if(options && options.length > 0){
      options.map((op, index) => {
        const value = selectOpened === 'assignedUserId' ? op.id : op.displayValue
        if(value === optionSelected){
          values.push(
              <li key={index} className="item-selected" style={op.color ? {color: op.color} : {color: 'white'} } onClick={() => onClickPopover(op.id)} data-test={`option-${index}`}>
                {op.displayValue}
              </li>
          )
        } else {
          values.push(
              <li key={index} className="change-submenu-item" style={op.color ? {color: op.color} : {color: '#4D4F5C'}} onClick={() => onClickPopover(op.id)} data-test={`option-${index}`}>
                {op.displayValue}
              </li>
          )
        }
      });
    }

    return (
        <div className="popover-general-message">
          <div className="popover-general-message-sub">
            <u>
              {values}
            </u>
          </div>
        </div>
    )
  }

  const onChangeCost = (costParam, index) => {
    let updatedCost = [...costs]
    let updatedError = {...error, costs: []}
    updatedCost[index] = costParam

    setCosts(updatedCost)
    setError(updatedError)
    props.handleUpdateData("hasChange");
  }

  const removeByIndex = (list, indexToRemove) => {
    return [...list.slice(0, indexToRemove), ...list.slice(indexToRemove + 1)]
  }

  const deleteCost = (index, costId) => {
    if (costId) {
      deleteTaskCostApi(costId, "task").then((response) => {
        const { responseCode, isAttached, receipts } = response
        if (responseCode !== SUCCESSFUL_CODE) {
          return
        }

        if (!isAttached) {
          const newCosts = removeByIndex(costs, index)
          setCosts(newCosts)
        }
      })
    } else {
      let costsNew = cloneDeep(costs)
      costsNew.splice(index, 1)
      setCosts(costsNew)
    }
  }

  const addCostToRepair = (cost) => {
    let arrCost = [];
    const fields = [COST_REPAIR_LABOR, COST_REPAIR_PART];
    const costInitial = getCostInitial();
    fields.map( f => {
      const objCost = {};
      if (cost.laborId && f === COST_REPAIR_LABOR) {
        objCost.id = cost.laborId;
      }
      if (cost.partId && f === COST_REPAIR_PART) {
        objCost.id = cost.partId;
      }
      objCost.costType = f;
      objCost.costValue = f === COST_REPAIR_LABOR ? formatToNumber(cost.labor) : formatToNumber(cost.part);
      if (cost.labor !== costInitial.labor || cost.part !== costInitial.part) {
        arrCost.push(objCost);
      }
    });
    return arrCost;
  }

  const saveCost = async (index, repairId) => {
    let arrCost = addCostToRepair();
    let payLoad= [{
      id: repairId,
      carId : repair.carId,
      description: cost.description,
      costs: arrCost,
    }];

    if(!repairId){
      const response = await saveTaskCost({listRepair: payLoad})
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        const newRepairId = data.repairs[0].id
        let updatedCost = {...cost}
        updatedCost.id = newRepairId;
        setCost(updatedCost)
        props.updateRepairId(newRepairId)
      }
    } else {
      payLoad = [{
        id: repairId,
        ...payLoad[0]
      }];

      await saveTaskCost({listRepair: payLoad})
    }
  }

  const onDropPhoto = (file, url) => {
    file.url = url;
    setReceiptsImages([...receiptsImages, file])
    props.handleUpdateData("hasChange");
  }

  const deleteReceipt = (id) => {
    deleteReceiptRepair(id).then( () => {
      let updatedReceiptsImages = deleteItemArray(id, receiptsImages);
      setReceiptsImages(updatedReceiptsImages)
    }).catch((error) =>{})
  }

  const updateCosts = (index, newRepairId, receipts) => {
    let costsToUpdate = cloneDeep(costs)
    let costFound = costsToUpdate[index]
    costFound = {...costFound, id: newRepairId, receipts: (receipts) ? receipts : costFound.receipts}
    costsToUpdate[index] = costFound
    setCosts(costsToUpdate)
    setIsLoading(false)
  }

  const onSaveReceipts = async (receipts, repairId, index) => {
    if (repairId) {
      setIsLoading(true)
      const response = await addReceiptRepair(receipts, repairId);
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        setReceiptsImages(data.receipts)
        setIsLoading(false)
        updateCosts(index, repairId, data.receipts)
      }
    }
  }

  const costOnBlur = (index, e) => {
    const name = e.target.name;
    const value = e.target.value;

    let updatedCosts = [...costs]
    updatedCosts[index][name] = usdNumberToFormat(value)

    setCosts(updatedCosts)
    props.handleUpdateData("hasChange");
  }

  const getImageOrInitials = (id) => {
    let imageOrInitials = "";

    usersSelected.forEach(user => {
      if (user.id === id){
        if (user.image !== "")
          imageOrInitials = <img className={'image-user-reply'} src={user.image}/>;
        else
          imageOrInitials = user.initials;
      }
    });

    return imageOrInitials;
  }

  const addNewComment = () => {
    let updatedComments = [...comments]

    if(newComment.trim() !== ""){
      updatedComments.push({comment: newComment.trim(), userId: userComment.id});
    }
    setComments(updatedComments)
    setNewComment('')
    props.handleUpdateData("hasChange");
  }

  const getError = () => {
    let error = {};
    if(task.title === '')
      error.title = true;

    if(task.typeTask === TASK_TYPE.REPAIR && isEmpty(task.carId))
      error.carId = true;

    const costErrors = getErrorsInCosts(costs)
    if (costErrors.length > 0) {
      error.costs = costErrors
    }

    return error;
  }

  const settingError = () => {
    let error = getError();
    if (!isEmpty(error)) {
      setError(error)
    }
  }

  const saveAllCost = async (carId, taskId) => {
    const payload = costs.map(cost => {
      let costOjb = {costs: addCostToRepair(cost), carId: carId, description: cost.description}
      if(cost?.id)
        costOjb.id = cost?.id
      return costOjb
    });
    return await saveTaskCost({listRepair: payload, taskId: taskId})
  }

  const getTaskData = () => {
    const subTasksTotalCost = costs.reduce((acc, cost) => acc + (parseFloat(cost.labor) + parseFloat(cost.part)),0)

    const costList = costs.map(cost => {
      return {
        carId: task.carId,
        description: cost.description,
        costs:
          [
            {
              costType: "labor",
              costValue: parseFloat(cost.labor)
            },
            {
              costType: "part",
              costValue: parseFloat(cost.part)
            },
          ]
      }
    })

    const costsRequest = {
      listRepair: costList,
      totalCost : subTasksTotalCost.toString(),
    }

    return {
      task: {
        ...task,
        id: task.id || getRandomId()
      },
      repairCost: costsRequest,
      comments: comments
    }
  }

  const validationData = async (carId, repairId) => {
    if (task.parentTaskId) {
      const updatedTask = {...task}
      updatedTask.parentTaskId = props.parentTaskId

      if(task.dueDate){
        const dueDate = Moment(task.dueDate).format("MM/DD/YYYY");
        updatedTask.dueDate = new Date(dueDate).getTime();
      }

      if(!task.completionDate){
        if(task.status === TASK_STATUS_DONE || task.status === TASK_STATUS_COMPLETE){
          updatedTask.completionDate = new Date().getTime();
        }else{
          delete updatedTask.completionDate;
        }
      }else{
        if(task.status !== TASK_STATUS_DONE && task.status !== TASK_STATUS_COMPLETE){
          delete updatedTask.completionDate;
        }
      }

      let requestForm = {};
      if (comments.length > 0) {
        requestForm = {task: updatedTask, comments: comments}
      } else {
        requestForm = {task: updatedTask}
      }

      requestForm = {...requestForm, task: {...requestForm.task, assignedUserId: task.assignedUserId  || undefined}}
      setIsLoading(true)
      const {responseCode, data} = await processTaskApi(requestForm)
      if (responseCode === SUCCESSFUL_CODE) {
        const {taskId} = data;
        if (task.typeTask === TASK_TYPE.REPAIR) {
          if(props.locationState.subtasksFromTask){
            const subtasksFromTaskUpdated = props.locationState.subtasksFromTask.map(el => {
              if(el.subTask.id === task.id){
                return {
                  ...el,
                  subTask: {
                    ...el.subTask,
                    status: task.status
                  }
                };
              }
              return el
            });

            props.locationState.updateStatusTask(subtasksFromTaskUpdated)
          }

          setIsLoading(false)
          await saveAllCost(updatedTask.carId, taskId)
          browserHistory.push({pathname: props.locationState.originPath})
        }
      } else {
        setIsLoading(false)
      }
    } else {
      browserHistory.push({pathname: '/main/task/new'})
    }
  }

  return (
    <div className={'body-edit-task'}>
      {isLoading ? <Loader/> : null}
      <div className={'task-info'}>
        <DPSectionHeader
          isClosed={isClosed}
          title='Subtask Info'
          accordionToggle={closeSection}
        />
        <div className={isClosed ? 'body-subtask-info closed' : 'body-subtask-info open'}>
          <div className={'task-info-title'}>
            <div>
              <label className={'title'}>Title</label><label className={'validation-field'}>*</label>
            </div>
            <input id='title' type='text' className={'task-input'} value={task.title}
                   onChange={onChangeTextTaskInfo}
                   style={(error && error.title) ? {borderColor: colors.errorLabel} : null}
            />
          </div>
          <div className={'task-info-description'}>
            <label className={'title'}>Description</label>
            <TextareaAutosize
                className={'task-input non-resize'}
                rowsMax={10}
                rowsMin={5}
                aria-label='maximum height'
                value={task.description}
                onChange={onChangeTextTaskInfo}
                id={'description'}
            />
          </div>
            <div className={'task-info-assigned'}>
                <label className={'title'}>Assigned to</label>
                <div id='assignedUserId' className={'task-select-assigned'}
                     onClick={(event) => handlePopover("assignedUserId", event)}
                >
                  <img className={'user-img'}
                       src={userAssigned.image ? userAssigned.image : `${pathServer.PATH_IMG}ic_account_circle.png`}/>
                  <label>{getNameUserSelected(userAssigned.name)}</label>
                  {selectOpened === ASSIGNED_USER_ID ?
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                                className={'icon-arrow-content'}
                                beforeInjection={svg => svg.classList.add('icon-arrow-expand')}/> :
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                                className={'icon-arrow-content'}
                                beforeInjection={svg => svg.classList.add('icon-arrow-expand')}/>
                  }
                </div>
              </div>
            <div className={'task-info-status'}>
                <label className={'title'}>Status</label>
                <div id='status' className={'task-select'} onClick={(event) => handlePopover('status', event)}>
                  <label>{statusValue}</label>
                  {selectOpened === STATUS ?
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                                className='icon-arrow-content'
                                beforeInjection={svg => svg.classList.add('icon-arrow-expand')}/> :
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                                className='icon-arrow-content'
                                beforeInjection={svg => svg.classList.add('icon-arrow-expand')}/>
                  }
                </div>
              </div>
            <div className={'task-info-priority'}>
                <label className={'title'}>Priority level</label>
                <div id='priority' className={'task-select'}
                     onClick={(event) => handlePopover('priority', event)}>
                  <label style={{color: priorityColorValue}}>{priorityValue}</label>
                  {selectOpened === PRIORITY ?
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                                className='icon-arrow-content'
                                beforeInjection={svg => svg.classList.add('icon-arrow-expand')}/> :
                      <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                                className='icon-arrow-content'
                                beforeInjection={svg => svg.classList.add('icon-arrow-expand')}/>
                  }
                </div>
              </div>
            <div className={'task-info-dueDate'}>
              <div><label className={'title'}>Due date</label></div>
                <DPKeyboardDatePicker
                    id={'dueDate'}
                    name={'dueDate'}
                    className={'task-dueDate'}
                    value={task.dueDate ? new Date(task.dueDate) : null}
                    onChange={(date) => handleChangeDate('dueDate', date)}
                />
            </div>
        </div>
        {
          !isClosed &&
          <DPCost
            showTitleHeader={true}
            showActionFooter={true}
            costList={costs}
            openRecipes={(index,repairId) => saveCost(index,repairId)}
            onChange={onChangeCost}
            isCarOrTask='T'
            onDropPhoto={onDropPhoto}
            receipts={receiptsImages}
            deleteReceipt={deleteReceipt}
            onSaveReceipts={onSaveReceipts}
            costOnBlur={costOnBlur}
            addNewCost={addNewCost}
            removeTaskRepair={deleteCost}
            showActionRow={true}
            isSubtask={true}
            errors={error?.costs ?? []}
            subTasksCost={0}
          />
        }
        </div>
      <div className={'task-comments-container'}>
        <label className={'comment-title'}>Comments</label>
        <div className={'comments-parent'}>
          {
            comments.length > 0 ?
              comments.map((com, index) => {
                return (
                  (com.userId === userComment.id) ?
                    <div key={`comment${index}`} className={'comment-item'}>
                      <div className={'comment-right'}>
                        <label>{com.comment}</label>
                      </div>
                      <div className={'icon-user-name'}>
                        {userComment.image ?
                          <img src={userComment.image}/> :
                          userComment.initials}
                      </div>
                    </div> :
                    <div key={`comment${index}`} className={'comment-item'}>
                      <div className={'icon-user-name'}>
                        {getImageOrInitials(com.userId)}
                      </div>
                      <div className={'comment-left'}>
                        <label>{com.comment}</label>
                      </div>
                    </div>
                )
              }) : null
          }
        </div>
        <div className={'new-comment-container'}>
          <input type='text' className={'input-comment'} value={newComment} placeholder='Type your comment...' onChange={onChangeNewComment}/>
          <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_send_24px.svg`}
                    className='icon-send-container' beforeInjection={ svg => svg.classList.add('icon-send')} onClick={addNewComment}/>
        </div>
      </div>

      { showPopover  ? <Popover anchorEl={anchorEl}
                                child={renderOptions()}
                                classPopover="popover-box-message"
                                handleRequestClose={handleRequestClose}/> : null
      }

    </div>
  )
})


export default SubTaskDetail